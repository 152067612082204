#read-terms-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

#read-terms-container .select-container {
    display: flex;
    flex-direction: column;
    width: 200px;
    margin-top: 5%;
}