#publication-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60px;
    background-color: #FFF;
    border-bottom: 1px #DDD solid
}

#publication-header .publication-option {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    margin-left: 4%;
    margin-right: 4%;
    font-size: 16px;
    border-radius: 50px;
}

@media screen and (max-width: 420px) {
    
}