#meet-up-participants-container {
    display: flex;
    flex-direction: column;
}

#meet-up-participants-container .text-title {
    align-self: center;
    text-align: center;
    font-size: 18px;
}

#meet-up-participants-container .participants-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-around;
    width: 100%;
    margin-top: 30px;
}

#meet-up-participants-container .participants-container .participants-item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}