#activities-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 100%;
}

#activities-container .activities-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
}

#activities-container .activities-content .activities-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 300px;
    margin: 30px 0;
    border-radius: 5px;
    background-color: #0091D0;
    font-size: 16px;
    font-weight: bold;
    color: #FFF;

    -webkit-box-shadow: 3px 3px 8px -1px rgba(102, 102, 102, 0.32);
    -moz-box-shadow: 3px 3px 8px -1px rgba(102, 102, 102, 0.32);
    box-shadow: 3px 3px 8px -1px rgba(102, 102, 102, 0.32);
}

#activities-container .activities-content .activities-button:hover {
    background-color: #0099db;
}

#activities-container .activities-content .activities-button .menu-image {
    height: 20px;
    margin-left: 10px;
    filter: brightness(0) invert(1);
}

#activities-container .activities-content .activities-button .too-big {
    height: 16px;
}

@media screen and (max-width: 330px) {
    #activities-container .activities-content .activities-button {
        height: 50px;
        padding: 25px 10px;
        width: 90%;
    }
}