#allAdvertisements-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#allAdvertisements-container .button-cancel-publication {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 50px;
    margin-bottom: 20px;
    border-radius: 25px;
    background-color: #0091D0;
    font-size: 16px;
    color: #FFF;

    -webkit-box-shadow: 3px 3px 8px -1px rgba(102,102,102,0.32);
    -moz-box-shadow: 3px 3px 8px -1px rgba(102,102,102,0.32);
    box-shadow: 3px 3px 8px -1px rgba(102,102,102,0.32);
}

#allAdvertisements-container .button-cancel-publication:hover {
    background-color: #0099db;
}

#allAdvertisements-container #myAdvertisementsItem-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    width: 700px;
    padding: 0px 5px;
    margin: 30px 20px;
    background-color: #EEE;
    border-radius: 10px;
}

#allAdvertisements-container #myAdvertisementsItem-content .header-adverts-item {
    display: flex;
    flex-direction: column;
    width: 680px;
    align-items: center;
    justify-content: flex-start;
}

#allAdvertisements-container #myAdvertisementsItem-content .advertsTitleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 680px;
    margin-bottom: 5px;
}

#allAdvertisements-container #myAdvertisementsItem-content .publication-title {
    display: flex;
    font-size: 18px;
    color: #5d1e03;
    margin: 10px 0;
    width: 100%;
}

#allAdvertisements-container #myAdvertisementsItem-content .publication-status-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-width: 30%;
    width: 100%;
}

#allAdvertisements-container #myAdvertisementsItem-content .publication-status {
    text-align: center;
    color: #333;
    border: 1px #333 solid;
    padding: 5px;
}

#allAdvertisements-container #myAdvertisementsItem-content .publication-name {
    display: flex;
    font-size: 18px;
    color: #5d1e03;
    overflow: hidden;
    margin: 10px 0;
}

#allAdvertisements-container #myAdvertisementsItem-content .advertsTextContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 680px;
}

#allAdvertisements-container #myAdvertisementsItem-content .advertsTextContainer p {
    display: -webkit-box;
    margin: 5px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
}

#allAdvertisements-container #myAdvertisementsItem-content .imageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

#allAdvertisements-container #myAdvertisementsItem-content .advertsImage {
    height: 436px;
    width: 680px;
    margin-top: 0px;
    object-fit: cover;
}

#allAdvertisements-container #myAdvertisementsItem-content .buttonLinkContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    width: 100%;
    background-color: #999;
}

#allAdvertisements-container #myAdvertisementsItem-content .link-text {
    padding: 0 10px;
    color: #FFF;
}

#allAdvertisements-container #myAdvertisementsItem-content .locations {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60px;
}

#allAdvertisements-container #myAdvertisementsItem-content .locations p {
    margin: 0px;
}

#allAdvertisements-container #myAdvertisementsItem-content .footer {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 680px;
    padding-top: 5px;
    padding-bottom: 10px;
}

#allAdvertisements-container #myAdvertisementsItem-content .footer p {
    margin: 2px 0px
}

#allAdvertisements-container #myAdvertisementsItem-content .footer .footer-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 3px;
}

#allAdvertisements-container #myAdvertisementsItem-content .footer .publication-date {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    padding-top: 20px;
    border-top: 1px #BBB solid;
}

#allAdvertisements-container #myAdvertisementsItem-content .footer .meet-up-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px #BBB solid;
}

#allAdvertisements-container #myAdvertisementsItem-content .footer .meet-up-attendees-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

#allAdvertisements-container #myAdvertisementsItem-content .footer .button-attendees {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: 50%;
    margin-top: 15px;
    padding: 5px;
}

@media screen and (max-width: 750px) {
    #allAdvertisements-container #myAdvertisementsItem-content {
        width: 600px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .header-adverts-item {
        width: 580px;
    }
    
    #allAdvertisements-container #myAdvertisementsItem-content .advertsTitleContainer {
        width: 580px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .advertsTextContainer {
        width: 580px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .advertsImage {
        height: 372px;
        width: 580px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .footer {
        width: 580px;
    }
}

@media screen and (max-width: 640px) {
    #allAdvertisements-container #myAdvertisementsItem-content {
        width: 500px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .header-adverts-item {
        width: 480px;
    }
    
    #allAdvertisements-container #myAdvertisementsItem-content .advertsTitleContainer {
        width: 480px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .advertsTextContainer {
        width: 480px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .advertsImage {
        height: 308px;
        width: 480px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .footer {
        width: 480px;
    }
}

@media screen and (max-width: 540px) {
    #allAdvertisements-container .button-cancel-publication {
        width: 250px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content {
        width: 410px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .header-adverts-item {
        width: 390px;
    }
    
    #allAdvertisements-container #myAdvertisementsItem-content .advertsTitleContainer {
        width: 390px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .advertsTextContainer {
        width: 390px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .advertsImage {
        height: 250px;
        width: 390px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .footer {
        width: 390px;
    }
}

@media screen and (max-width: 470px) {
    #allAdvertisements-container #myAdvertisementsItem-content {
        width: 85%;
    }
}

@media screen and (max-width: 450px) {
    #allAdvertisements-container .button-cancel-publication {
        width: 200px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .header-adverts-item {
        width: 350px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .advertsTitleContainer {
        width: 350px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .advertsStatusContainer {
        width: 350px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .advertsImage {
        height: 230px;
        width: 350px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .advertsTextContainer {
        width: 350px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .footer {
        width: 350px;
    }
}

@media screen and (max-width: 420px) {
    #allAdvertisements-container #myAdvertisementsItem-content {
        height: 550px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .header-adverts-item {
        width: 320px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .advertsTitleContainer {
        width: 320px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .advertsStatusContainer {
        width: 320px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .advertsImage {
        height: 210px;
        width: 320px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .advertsTextContainer {
        width: 320px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .footer {
        width: 320px;
    }
}

@media screen and (max-width: 380px) {
    #allAdvertisements-container .button-cancel-publication {
        width: 200px;
        font-size: 15px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .header-adverts-item {
        width: 300px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .advertsTitleContainer {
        width: 300px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .advertsStatusContainer {
        width: 300px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .advertsImage {
        height: 195px;
        width: 300px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .advertsTextContainer {
        width: 300px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .footer {
        width: 300px;
    }
}

@media screen and (max-width: 360px) {
    #allAdvertisements-container .button-cancel-publication {
        width: 180px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .header-adverts-item {
        width: 280px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .advertsTitleContainer {
        width: 280px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .advertsStatusContainer {
        width: 280px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .advertsImage {
        height: 180px;
        width: 280px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .advertsTextContainer {
        width: 280px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .footer {
        width: 280px;
    }
}

@media screen and (max-width: 335px) {
    #allAdvertisements-container #myAdvertisementsItem-content .header-adverts-item {
        width: 250px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .advertsTitleContainer {
        width: 250px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .advertsStatusContainer {
        width: 250px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .advertsImage {
        height: 165px;
        width: 250px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .advertsTextContainer {
        width: 250px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .footer {
        width: 250px;
    }
}

@media screen and (max-width: 290px) {
    #allAdvertisements-container #myAdvertisementsItem-content {
        height: 550px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .header-adverts-item {
        width: 220px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .advertsTitleContainer {
        width: 220px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .advertsStatusContainer {
        width: 220px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .advertsImage {
        height: 145px;
        width: 220px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .advertsTextContainer {
        width: 220px;
    }

    #allAdvertisements-container #myAdvertisementsItem-content .footer {
        width: 220px;
    }
}