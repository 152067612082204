body {
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
}

button {
    padding: 0;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

a {
    padding: 0;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    text-decoration: none;
}

input,
textarea {
    outline: none;
}

body,
textarea,
input {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}

html,
body,
#root {
    height: 100%;
}

.errorMessage {
    text-align: center;
    margin-bottom: 10px;
    color: #FF0000;
    font-size: 12px;
}

.successMessage {
    text-align: center;
    margin-bottom: 10px;
    color: #008000;
    font-size: 12px;
}

/* hide arrows on input type number*/

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/* ------------------------------ */