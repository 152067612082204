#login-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    height: 100%;
}

#form-login-container {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#login-container img {
    width: 250px;
    padding-bottom: 5%;
}

#login-container form {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 20%;
    min-width: 280px;
    padding-top: 30px;
}

#login-container .field-container {
    margin-bottom: 20px;
}
  
#login-container form label {
    position: absolute;
    font-size: 14px;
    padding: 0 12px;
    color: #999;
    font-weight: bold;
    pointer-events: none;

    transform: translate(0, 16px) scale(1);
    transform-origin: top left;
    transition: all 0.2s ease-out;
}

#login-container form .field-container:focus-within label {
    transform: translate(0,5px) scale(0.75);
}

#login-container form .field-container .Active {
    transform: translate(0,5px) scale(0.75);
}
  
#login-container form input {
    border: 1px solid #ddd;
    border-radius: 4px;
    height: 46px;
    padding: 14px 16px 0 10px;
    font-size: 14px;
    text-align: left;
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
}

#login-container form input:focus {
    outline: none;
}

#login-container .btn {
    border-radius: 5px;
    width: 100%;
    height: 42px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 20px;
    background-color: #FFF;
    color: #000;
    border-radius: 5px;

    -webkit-box-shadow: 3px 3px 8px -1px rgba(102,102,102,0.32);
    -moz-box-shadow: 3px 3px 8px -1px rgba(102,102,102,0.32);
    box-shadow: 3px 3px 8px -1px rgba(102,102,102,0.32);
}

#login-container .btn:hover {
    outline: none;
    background-color: #DDD;
    color: #000;
}

#login-container .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 5px;
    width: 100%;
    height: 42px;
    margin-top: 20px;
}

.errorMessage {
    text-align: center;
    margin-bottom: 10px;
    color: #FF0000;
    font-size: 12px;
}

@media screen and (max-width: 900px) {
    #form-login-container form {
        margin-top: 10%;
    }
}

@media screen and (max-width: 500px) {
    #form-login-container form {
        margin-top: 15%;
    }
}