#end-publications-modal-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 100px;
}

#end-publications-modal-container .end-publications-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 10px;
}

#end-publications-modal-container .end-publications-modal-content .modal-title {
    font-size: 16px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
    color: #444;
}

#end-publications-modal-container .end-publications-modal-content .close-modal {
    position: absolute;
    right: 1px;
    top: 1px;
    width: 30px;
    height: 30px;
    border-radius: 100px;
}

#end-publications-modal-container .end-publications-modal-content .close-modal:hover {
    background-color: #DDD;
}

#end-publications-modal-container .end-publications-modal-content .modal-button-container {
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: space-between;
    margin-bottom: 20px;
}

#end-publications-modal-container .end-publications-modal-content .one-button {
    justify-content: center;
}

#end-publications-modal-container .end-publications-modal-content .modal-button-container .modal-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 7px 20px;
    border-radius: 10px;
    color: #FFF;
}

#end-publications-modal-container .end-publications-modal-content .modal-button-container .cancel {
    background-color: #DF586B;
}

#end-publications-modal-container .end-publications-modal-content .modal-button-container .cancel:hover {
    background-color: #ec5f72;
}

#end-publications-modal-container .end-publications-modal-content .modal-button-container .confirm {
    background-color: #0FA11E;
}

#end-publications-modal-container .end-publications-modal-content .modal-button-container .confirm:hover {
    background-color: #10af20;
}