#announcements-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

#form-announcements-container {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#announcements-container form {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40%;
    min-width: 300px;
    padding-top: 30px;
}

#announcements-container .announc-header {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 5px;
    height: 50px;
}

#announcements-container .announc-header .image-header {
    height: 50px;
    width: 50px;
    border-radius: 100px;
    object-fit: cover;
    filter: blur(0);
    -webkit-filter: blur(0);
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
}

#announcements-container .announc-header .image-verified {
    position: absolute;
    align-self: flex-end;
    width: 15px;
    height: 15px;
    bottom: 2px;
    left: 40px;
}

#announcements-container .announc-header-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 15px;
}

#announcements-container .announc-header-name span {
    line-height: 1.3em;
}

#announcements-container .field-container {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
}

#announcements-container .field-container.first-select {
    border-bottom: 1px #DDD solid;
}
  
#announcements-container form label {
    font-size: 14px;
    color: #999;
    font-weight: bold;
    margin-bottom: 5px;
}
  
#announcements-container form input {
    border: 1px solid #ddd;
    border-radius: 4px;
    height: 40px;
    padding: 0 10px;
    font-size: 14px;
    text-align: left;
}

#announcements-container form input:focus {
    border-color: #333;
}

#announcements-container form textarea {
    border: 1px solid #ddd;
    border-radius: 4px;
    height: 100px;
    padding: 10px 10px;
    font-size: 14px;
    text-align: left;

}

#announcements-container form textarea:focus {
    border-color: #333;
}

#announcements-container .meet-up-dates-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

#announcements-container .meet-up-dates-container .meet-up-dates-content {
    display: flex;
    width: 45%;
}

#announcements-container .dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #DDD;
    border-radius: 4px;
    cursor: pointer;
    background-color: #F9F9F9;
    padding: 0 10px;
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

#announcements-container .activited {
    border: 1px dashed #FFF !important;
    background-color: #FFF !important;
}

#announcements-container .dropzone span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 15px 0;
    font-size: 11px;
    color: #99999F
}

#announcements-container button {
    border-radius: 5px;
    width: 100%;
    height: 42px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 10px;
    background-color: #0091D0;
    color: #FFF;
    border-radius: 50px;
    margin-bottom: 5%;

    -webkit-box-shadow: 3px 3px 8px -1px rgba(102,102,102,0.32);
    -moz-box-shadow: 3px 3px 8px -1px rgba(102,102,102,0.32);
    box-shadow: 3px 3px 8px -1px rgba(102,102,102,0.32);
}

#announcements-container button:hover {
    background-color: #0099db;
}

#announcements-container img {
    height: 200px;
    width: 290px;
}

#announcements-container .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 5px;
    width: 100%;
    height: 42px;
    margin-top: 10px;
}

#announcements-container .potentialNumberContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 30px;
    margin: 10px 0;
}

#announcements-container .potentialNumberLabel {
    font-weight: bold;
}

@media screen and (max-width: 1500px) {
    #announcements-container form {
        width: 50%;
    }
}

@media screen and (max-width: 1200px) {
    #announcements-container form {
        width: 60%;
    }
}

@media screen and (max-width: 900px) {
    #announcements-container form {
        width: 80%;
    }
}

@media screen and (max-width: 420px) {
    #announcements-container form {
        width: 90%;
        min-width: 200px;
    }
}