#header-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60px;
    background-color: #0091D0;
}

#header-container #header-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    background-color: #0091D0;

    -webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
    -moz-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
}

#advertisements-container #header-div:hover {
    background-color: #0091D0;
}


#header-container #title-header{
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #FFF;
}