#header-menu-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70px;
    background-color: #0091D0;
}

#header-menu-container .menu-options-container {
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: center;
    align-items: center;
}

#header-menu-container .menu-options-container .disabled {
    filter: brightness(0) invert(0.75);
    cursor: default;
}

#header-menu-container .menu-options-container .active {
    background-color: #0182BB !important;
}

#header-menu-container .menu-options-container .menu-option {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    color: #FFF;
}

#header-menu-container .menu-options-container .menu-option .menu-image {
    height: 18px;
    margin-left: 10px;
    filter: brightness(0) invert(1);
}

#header-menu-container .menu-options-container .menu-option .too-big {
    height: 14px;
}

#header-menu-container .menu-options-container .menu-option-dropdown {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    border-radius: 5px;
}

#header-menu-container .menu-options-container .rdn-control {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    margin-right: 22px;
    border: none;
    font-size: 18px;
    color: #FFF;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#header-menu-container .menu-options-container .rdn-control-arrow {
    display: none;
}

#header-menu-container .menu-options-container .rdn-control:hover {
    box-shadow: none;
}

#header-menu-container .menu-options-container .rdn-drop {
    background-color: #00a6ec;
    max-height: max-content;
    border-radius: 5px;
}

#header-menu-container .menu-options-container .rdn-drop-menu-option {
    display: flex;
    height: 50px;
    overflow: hidden;
}

#header-menu-container .menu-options-container .rdn-drop-menu-option:hover {
    background-color: transparent;
    text-decoration: underline;
    text-decoration-color: #FFF;
}

#header-menu-container .menu-options-container .is-selected {
    background-color: transparent;
}

#header-menu-container .menu-options-container .menu-option-dropdown .dropdown-image {
    position: absolute;
    right: 12px;
    height: 20px;
    filter: brightness(0) invert(1);
}

#header-menu-container .menu-options-container .menu-option-dropdown .item-dropdown {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px;
}

#header-menu-container .menu-options-container .menu-option-dropdown .item-dropdown .image-dropdown-container {
    width: 32px;
}

#header-menu-container .menu-options-container .menu-option-dropdown .item-dropdown .item-dropdown-image {
    width: 16px;
    object-fit: contain;
    filter: brightness(0) invert(1);
}

#header-menu-container .menu-options-container .menu-option-dropdown .item-dropdown .special-image {
    width: 18px;
}

#header-menu-container .menu-options-container .menu-option-dropdown .item-dropdown .disabled {
    filter: brightness(0) invert(0.75) !important;
    cursor: default;
}

#header-menu-container .menu-options-container .menu-option-dropdown .item-dropdown .item-text {
    display: flex;
    flex: 1;
    font-size: 16px;
    color: #FFF;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@media screen and (max-width: 970px) {
    #header-menu-container {
        display: none;
    }
}