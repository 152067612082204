#about-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 5%;
}

#about-container .logo {
    width: 250px;
    padding-bottom: 5%;
}

#about-container .presentation-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
}

#about-container .presentation-container .mobile-stores {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-top: 80px;
}

#about-container .presentation-container .mobile-stores a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #000;
    font-weight: bold;
    text-decoration: underline;
}

#about-container .presentation-container .mobile-stores a:visited { text-decoration: none; }
#about-container .presentation-container .mobile-stores a:hover { text-decoration: underline; }
#about-container .presentation-container .mobile-stores a:focus { text-decoration: underline; }
#about-container .presentation-container .mobile-stores a:hover, a:active { text-decoration: underline; }

#about-container .presentation-container .mobile-stores a p {
    color: '#000'
}

#about-container .presentation-container .mobile-stores img {
    width: 250px;
}

@media screen and (max-width: 1280px) {
    #about-container .presentation-container {
        width: 50%;
    }
}

@media screen and (max-width: 1070px) {
    #about-container .presentation-container {
        width: 80%;
    }
}

@media screen and (max-width: 700px) {
    #about-container {
        padding-top: 15%;
    }

    #about-container .presentation-container {
        width: 90%;
    }
}

@media screen and (max-width: 700px) {
    #about-container .presentation-container .mobile-stores img {
        width: 250px;
    }
}

@media screen and (max-width: 510px) {
    #about-container .presentation-container .mobile-stores img {
        width: 200px;
    }

    #about-container .presentation-container .mobile-stores a {
        font-size: 12px;
    }
}

@media screen and (max-width: 410px) {
    #about-container .presentation-container .mobile-stores img {
        width: 170px;
    }

    #about-container .presentation-container .mobile-stores a {
        font-size: 12px;
    }
}

@media screen and (max-width: 340px) {
    #about-container .presentation-container .mobile-stores img {
        width: 160px;
    }

    #about-container .presentation-container .mobile-stores a {
        font-size: 12px;
    }
}

@media screen and (max-width: 320px) {
    #about-container .presentation-container .mobile-stores img {
        width: 150px;
    }

    #about-container .presentation-container .mobile-stores a {
        font-size: 11px;
    }
}

@media screen and (max-width: 320px) {
    #about-container .presentation-container .mobile-stores img {
        width: 140px;
    }

    #about-container .presentation-container .mobile-stores a {
        font-size: 11px;
    }
}

@media screen and (max-width: 300px) {
    #about-container .logo {
        width: 200px;
    }
}