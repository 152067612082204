#myAdvertisements-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    width: 100%;
}

#myAdvertisements-container .spinner-container {
    position: absolute;
    top: 20%
}

#myAdvertisements-container .list-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
}