#ads-announ-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60px;
    background-color: #0091D0;
}

#ads-announ-header .logOut {
    position: absolute;
    top: 70px;
    right: 20px;
    padding: 5px;
    font-size: 14px;
}

#ads-announ-header .logOut:hover {
    text-decoration: underline;
}

#ads-announ-header .header-button {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    color: #FFF;
    margin: 0;
}

@media screen and (max-width: 420px) {
    #ads-announ-header .ads {
        font-size: 14px;
    }

    #ads-announ-header .announ {
        font-size: 14px;
    }
}

@media screen and (max-width: 335px) {
    #ads-announ-header .ads {
        font-size: 13px;
    }

    #ads-announ-header .announ {
        font-size: 13px;
    }
}