#empty-list-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    top: 30%
}

#empty-list-container img {
    width: 200px;
}

#empty-list-container p {
    font-size: 16px;
    margin-top: 20px;
    text-align: center;
    color: #AAA;
}