#advertisements-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

#form-advertisements-container {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#advertisements-container form {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40%;
    min-width: 300px;
    padding-top: 30px;
}

#advertisements-container .field-container {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
}

#advertisements-container .field-container.row {
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#advertisements-container .publication-info {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #333;
    border-radius: 5px;
    background-color: #FFF;
}

#advertisements-container .publication-info .message-icon-container {
    position: relative;
    padding: 0 15px;
}

#advertisements-container .publication-info .classified-ad-message-container {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

#advertisements-container .publication-info .classified-ad-message {
    display: flex;
    align-items: center;
    font-weight: bold;
}

#advertisements-container .publication-spacing {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin: 5px 0;
    width: 100%;
}

#advertisements-container .publication-spacing .first-spacing {
    position: absolute;
    height: 3px;
    width: 49%;
    left: 0;
    margin-top: 5px;
    background-color: #333;
}

#advertisements-container .publication-spacing .last-spacing {
    position: absolute;
    height: 3px;
    width: 49%;
    right: 0;
    margin-top: 5px;
    background-color: #333;
}

#advertisements-container .publication-info span {
    font-size: 13px;
    color: #000
}

#advertisements-container form label {
    font-size: 14px;
    color: #999;
    font-weight: bold;
    margin-bottom: 5px;
}

#advertisements-container .advertisementsTitle-icon-container {
    position: absolute;
    top: 30px;
    right: 5px;
}

#advertisements-container .meet-up-dates-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

#advertisements-container .meet-up-dates-container .meet-up-dates-content {
    display: flex;
    width: 45%;
}

#advertisements-container form input {
    border: 1px solid #ddd;
    border-radius: 4px;
    height: 40px;
    padding: 0 10px;
    font-size: 14px;
    text-align: left;
}

#advertisements-container #advertisementsTitle {
    padding-right: 35px;
}

#advertisements-container .tool-tip {
    max-width: 200px;
    white-space: normal;
}

#advertisements-container form input:focus {
    border-color: #333;
}

#advertisements-container form textarea {
    border: 1px solid #ddd;
    border-radius: 4px;
    height: 100px;
    padding: 10px 10px;
    font-size: 14px;
    text-align: left;

}

#advertisements-container form textarea:focus {
    border-color: #333;
}

#advertisements-container .dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #DDD;
    border-radius: 4px;
    cursor: pointer;
    background-color: #F9F9F9;
    padding: 0 10px;
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

#advertisements-container .activited {
    border: 1px dashed #FFF !important;
    background-color: #FFF !important;
}

#advertisements-container .dropzone span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 15px 0;
    font-size: 11px;
    color: #99999F
}

#advertisements-container .field-container .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 28px;
    margin-right: 10px;
}

#advertisements-container .field-container .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

#advertisements-container .field-container .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
}

#advertisements-container .field-container .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
}

#advertisements-container .field-container .switch input:checked+.slider {
    background-color: #2196F3;
}

#advertisements-container .field-container .switch input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

#advertisements-container .field-container .switch input:checked+.slider:before {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px);
}

#advertisements-container .field-container .publishAsSoonAsPossible-icon-container {
    margin-left: 10px;
}

#advertisements-container .btn {
    border-radius: 5px;
    width: 100%;
    height: 42px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 10px;
    background-color: #0091D0;
    color: #FFF;
    border-radius: 50px;
    margin-bottom: 5%;

    -webkit-box-shadow: 3px 3px 8px -1px rgba(102, 102, 102, 0.32);
    -moz-box-shadow: 3px 3px 8px -1px rgba(102, 102, 102, 0.32);
    box-shadow: 3px 3px 8px -1px rgba(102, 102, 102, 0.32);
}

#advertisements-container .btn:hover {
    background-color: #0099db;
}

#advertisements-container img {
    height: 200px;
    width: 290px;
}

#advertisements-container .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 5px;
    width: 100%;
    height: 42px;
    margin-top: 10px;
}

#advertisements-container .potentialNumberContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 30px;
    margin-top: 10px;
}

#advertisements-container .potentialNumberLabel {
    font-weight: bold;
}

#advertisements-container .advertisementsLocalityContainer {
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

#advertisements-container .advertisementsLocalityContainer .btn {
    width: 49%;
    font-weight: normal;
}

#advertisements-container .value-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
}

#advertisements-container .value-container label {
    color: #000
}

@media screen and (max-width: 1500px) {
    #advertisements-container form {
        width: 50%;
    }
}

@media screen and (max-width: 1376px) {
    #advertisements-container .publication-spacing .first-spacing {
        width: 48.8%;
    }

    #advertisements-container .publication-spacing .last-spacing {
        width: 48.8%;
    }
}

@media screen and (max-width: 1200px) {
    #advertisements-container form {
        width: 60%;
    }

    #advertisements-container .publication-spacing .first-spacing {
        width: 49%;
    }

    #advertisements-container .publication-spacing .last-spacing {
        width: 49%;
    }
}

@media screen and (max-width: 1100px) {
    #advertisements-container .publication-spacing .first-spacing {
        width: 48.8%;
    }

    #advertisements-container .publication-spacing .last-spacing {
        width: 48.8%;
    }
}

@media screen and (max-width: 900px) {
    #advertisements-container form {
        width: 80%;
    }

    #advertisements-container .publication-spacing .first-spacing {
        width: 49%;
    }

    #advertisements-container .publication-spacing .last-spacing {
        width: 49%;
    }
}

@media screen and (max-width: 780px) {
    #advertisements-container .publication-spacing .first-spacing {
        width: 48.8%;
    }

    #advertisements-container .publication-spacing .last-spacing {
        width: 48.8%;
    }
}

@media screen and (max-width: 780px) {
    #advertisements-container .publication-spacing .first-spacing {
        width: 48.5%;
    }

    #advertisements-container .publication-spacing .last-spacing {
        width: 48.5%;
    }
}

@media screen and (max-width: 540px) {
    #advertisements-container .publication-spacing .first-spacing {
        width: 48.1%;
    }

    #advertisements-container .publication-spacing .last-spacing {
        width: 48.1%;
    }
}

@media screen and (max-width: 420px) {
    #advertisements-container form {
        width: 90%;
        min-width: 200px;
    }
}

@media screen and (max-width: 360px) {
    #advertisements-container .publication-spacing .first-spacing {
        width: 47.4%;
    }

    #advertisements-container .publication-spacing .last-spacing {
        width: 47.4%;
    }
}