#header-menu-mobile-container {
    display: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70px;
    background-color: #0091D0;
    z-index: 5;
}

.sticky {
    position: fixed;
}

.sticky + div {
    margin-top: 70px;
}

#header-menu-mobile-container .menu-options-container {
    display: flex;
    flex-direction: row;
    width: 95%;
    justify-content: flex-start;
    align-items: center;
}

#header-menu-mobile-container .menu-options-container .menu-space {
    height: 30px;
    width: 30px;
}

#header-menu-mobile-container .menu-options-container .menu-screen-name-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
}

#header-menu-mobile-container .menu-options-container .menu-screen-name-container .menu-text {
    font-size: 30px;
    text-align: center;
    color: #FFF;
}

#header-menu-mobile-container .menu-options-container .menu-screen-name-container .menu-img {
    height: 30px;
    object-fit: contain;
    margin-left: 15px;
    filter: brightness(0) invert(1);
}

#header-menu-mobile-container .menu-options-container .rdn {
    position: fixed;
    z-index: 10;
}

#header-menu-mobile-container .menu-options-container .rdn-control {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    margin: 0;
    border: none;
    color: transparent;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#header-menu-mobile-container .menu-options-container .rdn-control:hover {
    box-shadow: none;
}

#header-menu-mobile-container .menu-options-container .rdn-control-arrow {
    display: flex;
    position: absolute;
    align-self: center;
}

#header-menu-mobile-container .menu-options-container .rdn-drop {
    background-color: #00a6ec;
    max-height: max-content;
    width: 230px;
    padding-left: 5px;
    border-radius: 5px;
}

#header-menu-mobile-container .menu-options-container .rdn-drop-menu-option {
    display: flex;
    height: 60px;
    overflow: hidden;
}

#header-menu-mobile-container .menu-options-container .rdn-drop-menu-option:hover {
    background-color: transparent;
    text-decoration: underline;
    text-decoration-color: #FFF;
}

#header-menu-mobile-container .menu-options-container .is-selected {
    background-color: transparent;
}

#header-menu-mobile-container .menu-options-container .item-dropdown {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px;
}

#header-menu-mobile-container .menu-options-container .item-dropdown .image-dropdown-container {
    width: 32px;
}

#header-menu-mobile-container .menu-options-container .item-dropdown .item-dropdown-menu-image {
    width: 18px;
    object-fit: contain;
    filter: brightness(0) invert(1);
}

#header-menu-mobile-container .menu-options-container .item-dropdown .item-dropdown-settings-image {
    width: 14px;
    object-fit: contain;
    filter: brightness(0) invert(1);
}

#header-menu-mobile-container .menu-options-container .item-dropdown .disabled {
    filter: brightness(0) invert(0.75) !important;
    cursor: default;
}

#header-menu-mobile-container .menu-options-container .item-dropdown .special-image {
    width: 16px;
}

#header-menu-mobile-container .menu-options-container .item-dropdown .item-menu-text {
    display: flex;
    flex: 1;
    font-size: 16px;
    color: #FFF;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


#header-menu-mobile-container .menu-options-container .item-dropdown .settings-dropdown {
    display: flex;
}

#header-menu-mobile-container .menu-options-container .item-dropdown .settings-dropdown .item-menu-text {
    display: flex;
    flex: 1;
    font-size: 14px;
    color: #FFF;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#header-menu-mobile-container .menu-options-container .item-dropdown .settings-dropdown .settings-label {
    font-size: 16px;
}

#header-menu-mobile-container .menu-options-container .item-dropdown .settings-dropdown .settings-label:hover {
    text-decoration: underline;
}

#header-menu-mobile-container .menu-options-container .item-dropdown .settings-dropdown .select {
    font-weight: bold;
    text-decoration: underline;
}

#header-menu-mobile-container .menu-options-container .item-dropdown .settings-dropdown .rdn {
    top: 173px;
}

#header-menu-mobile-container .menu-options-container .item-dropdown .settings-dropdown .rdn-control {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    width: 180px;
    height: 59px;
    border: none;
    font-size: 18px;
    color: #FFF;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#header-menu-mobile-container .menu-options-container .item-dropdown .settings-dropdown .rdn-control-placeholder {
    display: none;
}

#header-menu-mobile-container .menu-options-container .item-dropdown .settings-dropdown .rdn-control:hover {
    box-shadow: none;
    text-decoration: underline;
}

#header-menu-mobile-container .menu-options-container .item-dropdown .settings-dropdown .rdn-drop {
    position: relative;
    bottom: 0px;
    left: -48px;
    background-color: #00a6ec;
    max-height: max-content;
    border-radius: 5px;
    border-top-color: transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

#header-menu-mobile-container .menu-options-container .item-dropdown .settings-dropdown .rdn-drop-menu-option {
    display: flex;
    height: 50px;
    overflow: hidden;
    margin-left: 15px;
}

#header-menu-mobile-container .menu-options-container .item-dropdown .settings-dropdown .rdn-drop-menu-option:hover {
    background-color: transparent;
    text-decoration: underline;
    text-decoration-color: #FFF;
}

#header-menu-mobile-container .menu-options-container .item-dropdown .settings-dropdown .is-selected {
    background-color: transparent;
}

@media screen and (max-width: 970px) {
    #header-menu-mobile-container {
        display: flex;
    }
}

@media screen and (max-width: 500px) {
    #header-menu-mobile-container .menu-options-container .menu-screen-name-container .menu-text {
        font-size: 25px;
    }
}

@media screen and (max-width: 450px) {
    #header-menu-mobile-container .menu-options-container .menu-screen-name-container .menu-text {
        font-size: 22px;
    }
}