#myAdvertisementsItem-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    width: 410px;
    max-width: 410px;
    padding: 0px 5px;
    margin: 10px 20px;
    background-color: #EEE;
    border-radius: 10px;
    height: 600px;
}

#myAdvertisementsItem-container .header-adverts-item {
    display: flex;
    flex-direction: column;
    width: 390px;
    align-items: center;
    justify-content: flex-start;
}

#myAdvertisementsItem-container .advertsTitleContainer {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 390px;
    height: 40px;
    margin-bottom: 5px;
    text-decoration: underline;
    text-decoration-color: #5d1e03;
    color: #5d1e03;
    -webkit-user-select: all;
    -moz-user-select: all;
    -ms-user-select: all;
    user-select: all;
}

#myAdvertisementsItem-container .advertsTitleContainer:hover {
    color: #af3a08;
    text-decoration-color: #af3a08;
}

#myAdvertisementsItem-container .publication-name {
    display: -webkit-box;
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

#myAdvertisementsItem-container .advertsTextContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 390px;
    height: 50px;
}

#myAdvertisementsItem-container .advertsTextContainer p {
    display: -webkit-box;
    margin: 5px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
}

#myAdvertisementsItem-container .imageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

#myAdvertisementsItem-container .advertsImage {
    height: 250px;
    width: 390px;
    margin-top: 0px;
    object-fit: cover;
}

#myAdvertisementsItem-container .buttonLinkContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    width: 370px;
    padding-right: 10px;
    padding-left: 10px;
    background-color: #999;
}

#myAdvertisementsItem-container .locations {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60px;
}

#myAdvertisementsItem-container .locations p {
    margin: 0px;
}

#myAdvertisementsItem-container .footer {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 390px;
    padding-top: 5px;
    padding-bottom: 10px;
}

#myAdvertisementsItem-container .footer p {
    margin: 2px 0px
}

#myAdvertisementsItem-container .footer .footer-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 3px;
}

#myAdvertisementsItem-container .footer .footer-item .text-date {
    display: flex;
    align-items: center;
    font-weight: bold;
}

#myAdvertisementsItem-container .footer .footer-item .date {
    font-weight: normal;
}

#myAdvertisementsItem-container .footer .footer-space {
    height: 22px;
}

#myAdvertisementsItem-container .footer .meet-up-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px #BBB solid;
}

#myAdvertisementsItem-container .footer .meet-up-buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

#myAdvertisementsItem-container .footer .meet-up-buttons-container .meet-up-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 45%;
    padding: 12px;
    border-radius: 25px;
    font-weight: bold;
    text-align: center;
    color: #FFF;
}

#myAdvertisementsItem-container .footer .meet-up-attendees-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
}

#myAdvertisementsItem-container .footer .meet-up-attendees-container .error-meet-up-message{
    color: red;
    margin-bottom: 5px;
}

#myAdvertisementsItem-container .footer .meet-up-attendees-container .success-meet-up-message{
    color: green;
    margin-bottom: 5px;
}

@media screen and (max-width: 470px) {
    #myAdvertisementsItem-container {
        width: 85%;
    }
}

@media screen and (max-width: 450px) {
    #myAdvertisementsItem-container .header-adverts-item {
        width: 350px;
    }

    #myAdvertisementsItem-container .advertsTitleContainer {
        width: 350px;
    }

    #myAdvertisementsItem-container .advertsStatusContainer {
        width: 350px;
    }

    #myAdvertisementsItem-container .advertsImage {
        height: 230px;
        width: 350px;
    }

    #myAdvertisementsItem-container .buttonLinkContainer {
        width: 330px;
    }

    #myAdvertisementsItem-container .advertsTextContainer {
        width: 350px;
    }

    #myAdvertisementsItem-container .footer {
        width: 350px;
    }
}

@media screen and (max-width: 420px) {
    #myAdvertisementsItem-container {
        height: 550px;
    }

    #myAdvertisementsItem-container .header-adverts-item {
        width: 320px;
    }

    #myAdvertisementsItem-container .advertsTitleContainer {
        width: 320px;
    }

    #myAdvertisementsItem-container .advertsStatusContainer {
        width: 320px;
    }

    #myAdvertisementsItem-container .advertsImage {
        height: 210px;
        width: 320px;
    }

    #myAdvertisementsItem-container .buttonLinkContainer {
        width: 300px;
    }

    #myAdvertisementsItem-container .advertsTextContainer {
        width: 320px;
    }

    #myAdvertisementsItem-container .footer {
        width: 320px;
    }
}

@media screen and (max-width: 380px) {
    #myAdvertisementsItem-container .header-adverts-item {
        width: 300px;
    }

    #myAdvertisementsItem-container .advertsTitleContainer {
        width: 300px;
    }

    #myAdvertisementsItem-container .advertsStatusContainer {
        width: 300px;
    }

    #myAdvertisementsItem-container .advertsImage {
        height: 195px;
        width: 300px;
    }

    #myAdvertisementsItem-container .buttonLinkContainer {
        width: 280px;
    }

    #myAdvertisementsItem-container .advertsTextContainer {
        width: 300px;
    }

    #myAdvertisementsItem-container .footer {
        width: 300px;
    }
}

@media screen and (max-width: 360px) {
    #myAdvertisementsItem-container .header-adverts-item {
        width: 280px;
    }

    #myAdvertisementsItem-container .advertsTitleContainer {
        width: 280px;
    }

    #myAdvertisementsItem-container .advertsStatusContainer {
        width: 280px;
    }

    #myAdvertisementsItem-container .advertsImage {
        height: 180px;
        width: 280px;
    }

    #myAdvertisementsItem-container .buttonLinkContainer {
        width: 260px;
    }

    #myAdvertisementsItem-container .advertsTextContainer {
        width: 280px;
    }

    #myAdvertisementsItem-container .footer {
        width: 280px;
    }
}

@media screen and (max-width: 335px) {
    #myAdvertisementsItem-container .header-adverts-item {
        width: 250px;
    }

    #myAdvertisementsItem-container .advertsTitleContainer {
        width: 250px;
    }

    #myAdvertisementsItem-container .advertsStatusContainer {
        width: 250px;
    }

    #myAdvertisementsItem-container .advertsImage {
        height: 165px;
        width: 250px;
    }

    #myAdvertisementsItem-container .buttonLinkContainer {
        width: 230px;
    }

    #myAdvertisementsItem-container .advertsTextContainer {
        width: 250px;
    }

    #myAdvertisementsItem-container .footer {
        width: 250px;
    }
}

@media screen and (max-width: 290px) {
    #myAdvertisementsItem-container {
        height: 550px;
    }

    #myAdvertisementsItem-container .header-adverts-item {
        width: 220px;
    }

    #myAdvertisementsItem-container .advertsTitleContainer {
        width: 220px;
    }

    #myAdvertisementsItem-container .advertsStatusContainer {
        width: 220px;
    }

    #myAdvertisementsItem-container .advertsImage {
        height: 145px;
        width: 220px;
    }

    #myAdvertisementsItem-container .buttonLinkContainer {
        width: 200px;
    }

    #myAdvertisementsItem-container .advertsTextContainer {
        width: 220px;
    }

    #myAdvertisementsItem-container .footer {
        width: 220px;
    }
}