#allAnnouncements-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#allAnnouncements-container .button-cancel-publication {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 50px;
    margin-bottom: 20px;
    border-radius: 25px;
    background-color: #0091D0;
    font-size: 16px;
    color: #FFF;

    -webkit-box-shadow: 3px 3px 8px -1px rgba(102,102,102,0.32);
    -moz-box-shadow: 3px 3px 8px -1px rgba(102,102,102,0.32);
    box-shadow: 3px 3px 8px -1px rgba(102,102,102,0.32);
}

#allAnnouncements-container .button-cancel-publication:hover {
    background-color: #0099db;
}

#allAnnouncements-container #myAnnouncementsItem-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    width: 700px;
    padding: 0px 5px;
    margin: 30px 20px;
    background-color: #EEE;
    border-radius: 10px;
}

#allAnnouncements-container #myAnnouncementsItem-content .status-text {
    text-align: center;
    margin-bottom: 15px;
    font-weight: bold;
}

#allAnnouncements-container #myAnnouncementsItem-content .announc-header {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
    height: 50px;
}

#allAnnouncements-container #myAnnouncementsItem-content .announc-header .image-header {
    height: 50px;
    width: 50px;
    border-radius: 100px;
    object-fit: cover;
    filter: blur(0);
    -webkit-filter: blur(0);
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
}

#allAnnouncements-container #myAnnouncementsItem-content .announc-header .image-verified {
    position: absolute;
    align-self: flex-end;
    width: 15px;
    height: 15px;
    bottom: 2px;
    left: 40px;
}

#allAnnouncements-container #myAnnouncementsItem-content .announc-header-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 15px;
}

#allAnnouncements-container #myAnnouncementsItem-content .announc-header-name span {
    line-height: 1.3em;
}

#allAnnouncements-container #myAnnouncementsItem-content .header-announc-item {
    display: flex;
    flex-direction: column;
    width: 680px;
    align-items: center;
    justify-content: flex-start;
}

#allAnnouncements-container #myAnnouncementsItem-content .announcTitleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 680px;
    height: 40px;
    margin-bottom: 5px;
    -webkit-user-select: all;
    -moz-user-select: all;
    -ms-user-select: all;
    user-select: all;
}

#allAnnouncements-container #myAnnouncementsItem-content .publication-name {
    font-size: 18px;
    color: #0074a6;
    text-align: center;
}

#allAnnouncements-container #myAnnouncementsItem-content .announcTextContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 680px;
    height: 50px;
}

#allAnnouncements-container #myAnnouncementsItem-content .announcTextContainer p {
    display: -webkit-box;
    margin: 5px 0;
}

#allAnnouncements-container #myAnnouncementsItem-content .survey-question-title {
    width: 680px;
    font-weight: bold;
}

#allAnnouncements-container #myAnnouncementsItem-content .survey-data-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 205px;
}

#allAnnouncements-container #myAnnouncementsItem-content .survey-data-container .survey-data-option {
    display: flex;
    align-items: flex-end;
    margin: 3px 0;
    width: 100%;
}

#allAnnouncements-container #myAnnouncementsItem-content .survey-data-container .survey-data-option .survey-data-check {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 25px;
    margin-right: 8px;
    border: 2.5px solid #0074a6;
    border-radius: 6px;
}

#allAnnouncements-container #myAnnouncementsItem-content .survey-data-container .survey-data-option .survey-bar-container {
    position: relative;
    width: 85%;
}

#allAnnouncements-container #myAnnouncementsItem-content .survey-data-container .survey-data-option .option-text {
    line-height: 1.3em;
}

#allAnnouncements-container #myAnnouncementsItem-content .survey-data-container .survey-data-option .percentage-text {
    position: absolute;
    right: -45px;
    bottom: 2px;
    line-height: 1.2em;
}

#allAnnouncements-container #myAnnouncementsItem-content .survey-data-container .survey-data-option .wrapper {
    border: 1px solid #0074a6;
    border-radius: 5px;
}

#allAnnouncements-container #myAnnouncementsItem-content .survey-data-container .survey-data-option .container {
    display: flex;
    justify-content: center;
    width: 100%;
}

#allAnnouncements-container #myAnnouncementsItem-content .survey-data-container .survey-data-option .label {
    position: absolute;
    color: #000;
}

#allAnnouncements-container #myAnnouncementsItem-content .imageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

#allAnnouncements-container #myAnnouncementsItem-content .announcImage {
    height: 436px;
    width: 680px;
    margin-top: 0px;
    object-fit: cover;
}

#allAnnouncements-container #myAnnouncementsItem-content .buttonLinkContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    width: 370px;
    padding-right: 10px;
    padding-left: 10px;
    background-color: #999;
}

#allAnnouncements-container #myAnnouncementsItem-content .locations {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60px;
}

#allAnnouncements-container #myAnnouncementsItem-content .locations p {
    margin: 0px;
}

#allAnnouncements-container #myAnnouncementsItem-content .footer {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 680px;
    padding-top: 5px;
    padding-bottom: 10px;
}

#allAnnouncements-container #myAnnouncementsItem-content .footer p {
    margin: 2px 0px
}

#allAnnouncements-container #myAnnouncementsItem-content .footer .footer-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 3px;
}

#allAnnouncements-container #myAnnouncementsItem-content .footer .publication-date {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    padding-top: 10px;
    border-top: 1px #BBB solid;
}

#allAnnouncements-container #myAnnouncementsItem-content .footer .footer-space {
    height: 22px;
}

#allAnnouncements-container #myAnnouncementsItem-content .footer .meet-up-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px #BBB solid;
}

#allAnnouncements-container #myAnnouncementsItem-content .footer .meet-up-buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

#allAnnouncements-container #myAnnouncementsItem-content .footer .meet-up-buttons-container .meet-up-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 45%;
    padding: 12px;
    border-radius: 25px;
    font-weight: bold;
    text-align: center;
    color: #FFF;
}

#allAnnouncements-container #myAnnouncementsItem-content .footer .meet-up-attendees-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
}

#allAnnouncements-container #myAnnouncementsItem-content .footer .button-attendees {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: 50%;
    margin-top: 15px;
    padding: 5px;
}

@media screen and (max-width: 750px) {
    #allAnnouncements-container #myAnnouncementsItem-content {
        width: 600px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .header-announc-item {
        width: 580px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .announcTitleContainer {
        width: 580px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .announcTextContainer {
        width: 580px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .survey-question-title {
        width: 580px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .announcImage {
        height: 372px;
        width: 580px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .footer {
        width: 580px;
    }
}

@media screen and (max-width: 640px) {
    #allAnnouncements-container #myAnnouncementsItem-content {
        width: 500px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .header-announc-item {
        width: 480px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .announcTitleContainer {
        width: 480px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .announcTextContainer {
        width: 480px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .survey-question-title {
        width: 480px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .survey-data-container .survey-data-option .survey-bar-container {
        width: 80%;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .announcImage {
        height: 308px;
        width: 480px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .footer {
        width: 480px;
    }
}

@media screen and (max-width: 540px) {
    #allAnnouncements-container .button-cancel-publication {
        width: 250px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content {
        width: 410px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .header-announc-item {
        width: 390px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .announcTitleContainer {
        width: 390px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .announcTextContainer {
        width: 390px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .survey-question-title {
        width: 390px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .survey-data-container .survey-data-option .survey-bar-container {
        width: 75%;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .announcImage {
        height: 250px;
        width: 390px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .footer {
        width: 390px;
    }
}

@media screen and (max-width: 470px) {
    #allAnnouncements-container #myAnnouncementsItem-content {
        width: 85%;
    }
}

@media screen and (max-width: 450px) {
    #allAnnouncements-container .button-cancel-publication {
        width: 200px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .header-announc-item {
        width: 350px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .announcTitleContainer {
        width: 350px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .advertsStatusContainer {
        width: 350px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .announcImage {
        height: 230px;
        width: 350px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .buttonLinkContainer {
        width: 330px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .announcTextContainer {
        width: 350px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .survey-question-title {
        width: 350px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .footer {
        width: 350px;
    }
}

@media screen and (max-width: 420px) {
    #allAnnouncements-container #myAnnouncementsItem-content {
        height: 550px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .header-announc-item {
        width: 320px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .announcTitleContainer {
        width: 320px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .advertsStatusContainer {
        width: 320px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .announcImage {
        height: 210px;
        width: 320px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .buttonLinkContainer {
        width: 300px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .announcTextContainer {
        width: 320px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .survey-question-title {
        width: 320px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .survey-data-container .survey-data-option .label {
        font-size: 12px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .footer {
        width: 320px;
    }
}

@media screen and (max-width: 380px) {
    #allAnnouncements-container .button-cancel-publication {
        width: 200px;
        font-size: 15px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .header-announc-item {
        width: 300px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .announcTitleContainer {
        width: 300px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .advertsStatusContainer {
        width: 300px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .announcImage {
        height: 195px;
        width: 300px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .buttonLinkContainer {
        width: 280px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .announcTextContainer {
        width: 300px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .survey-question-title {
        width: 300px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .footer {
        width: 300px;
    }
}

@media screen and (max-width: 360px) {
    #allAnnouncements-container .button-cancel-publication {
        width: 180px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .header-announc-item {
        width: 280px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .announcTitleContainer {
        width: 280px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .advertsStatusContainer {
        width: 280px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .announcImage {
        height: 180px;
        width: 280px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .buttonLinkContainer {
        width: 260px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .announcTextContainer {
        width: 280px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .survey-question-title {
        width: 280px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .survey-data-container .survey-data-option .survey-bar-container {
        width: 70%;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .footer {
        width: 280px;
    }
}

@media screen and (max-width: 335px) {
    #allAnnouncements-container #myAnnouncementsItem-content .header-announc-item {
        width: 250px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .announcTitleContainer {
        width: 250px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .advertsStatusContainer {
        width: 250px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .announcImage {
        height: 165px;
        width: 250px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .buttonLinkContainer {
        width: 230px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .announcTextContainer {
        width: 250px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .survey-question-title {
        width: 250px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .survey-data-container .survey-data-option .label {
        font-size: 10px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .footer {
        width: 250px;
    }
}

@media screen and (max-width: 290px) {
    #allAnnouncements-container #myAnnouncementsItem-content {
        height: 550px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .header-announc-item {
        width: 220px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .announcTitleContainer {
        width: 220px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .advertsStatusContainer {
        width: 220px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .announcImage {
        height: 145px;
        width: 220px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .buttonLinkContainer {
        width: 200px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .announcTextContainer {
        width: 220px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .survey-question-title {
        width: 220px;
    }

    #allAnnouncements-container #myAnnouncementsItem-content .footer {
        width: 220px;
    }
}