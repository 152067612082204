#contactUs-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

#form-contactUs-container {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#contactUs-container img {
    width: 250px;
    padding-top: 3%;
}

#contactUs-container form {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
    min-width: 300px;
    padding-top: 30px;
}

#contactUs-container .field-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
}
  
#contactUs-container form label {
    font-size: 14px;
    color: #999;
    font-weight: bold;
    margin-bottom: 5px;
}
  
#contactUs-container form input {
    border: 1px solid #ddd;
    border-radius: 4px;
    height: 40px;
    padding: 0 10px;
    font-size: 14px;
    text-align: left;
}

#contactUs-container form input:focus {
    border-color: #333 !important;
}

#contactUs-container form textarea {
    border: 1px solid #ddd;
    border-radius: 4px;
    height: 100px;
    padding: 10px 10px;
    font-size: 14px;
    text-align: left;

}

#contactUs-container form textarea:focus {
    border-color: #333 !important;
}

#contactUs-container .btn {
    border-radius: 5px;
    width: 100%;
    height: 42px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 20px;
    background-color: #FFF;
    color: #000;
    border-radius: 5px;
    margin-bottom: 50px;

    -webkit-box-shadow: 3px 3px 8px -1px rgba(102,102,102,0.32);
    -moz-box-shadow: 3px 3px 8px -1px rgba(102,102,102,0.32);
    box-shadow: 3px 3px 8px -1px rgba(102,102,102,0.32);
}

#contactUs-container .btn:hover {
    outline: none;
    background-color: #DDD;
    color: #000;
}

#contactUs-container .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 5px;
    width: 100%;
    height: 42px;
    margin-top: 20px;
}

@media screen and (max-width: 900px) {
    #contactUs-container form {
        width: 80%;
    }
}

@media screen and (max-width: 310px) {
    #contactUs-container form {
        width: 90% ;
        min-width: 200px;
    }
}