#terms-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 5%;
}

#terms-container img {
    width: 250px;
    padding-bottom: 5%;
}

#terms-container .presentation-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
}

#terms-container .presentation-container .terms-title {
    font-size: 15px;
    text-decoration: underline;
}

#terms-container .terms-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    max-width: 400px;
    margin-top: 50px;
}

#terms-container .terms-button {
    height: 50px;
    width: 50px;
    border-radius: 50px;
    background-color: #FFF;
    font-weight: bold;
    
    -webkit-box-shadow: 3px 3px 8px -1px rgba(102,102,102,0.32);
    -moz-box-shadow: 3px 3px 8px -1px rgba(102,102,102,0.32);
    box-shadow: 3px 3px 8px -1px rgba(102,102,102,0.32);
}

#terms-container .terms-button:hover {
    background-color: #DDD;
}

@media screen and (max-width: 1070px) {
    #terms-container .presentation-container {
        width: 80%;
    }
}

@media screen and (max-width: 1280px) {
    #terms-container .presentation-container {
        width: 50%;
    }
}

@media screen and (max-width: 700px) {
    #terms-container {
        padding-top: 15%;
    }

    #terms-container .presentation-container {
        width: 90%;
    }
}

@media screen and (max-width: 300px) {
    #terms-container img {
        width: 200px;
    }
}