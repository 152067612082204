#login-footer {
    display: flex;
    width: 100%;
    height: 40px;
    justify-content: center;
    align-items: center;
}

#login-footer button {
    padding: 5px;
    font-size: 13px;
    margin-left: 5px;
    margin-right: 5px;
}

#login-footer button:hover {
    text-decoration: underline;
    cursor: pointer;
}