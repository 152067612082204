#about-advertisements-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 40px 50px;
}

#about-advertisements-container .price-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 13px 30px;
    border-radius: 100px;
    background-color: #0091D0;
    color: #FFF;
    font-weight: bold;
}

#about-advertisements-container .explanation-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    text-align: justify;
    margin-bottom: 5%;
    width: 70%;
}

#about-advertisements-container .explanation-container p {
    margin: 10px 3px;
    font-size: 18px;
}

@media screen and (max-width: 1060px) {
    #about-advertisements-container .explanation-container {
        width: 90%;
    }
}

@media screen and (max-width: 600px) {
    #about-advertisements-container .explanation-container {
        width: 90%;
    }
    #about-advertisements-container {
        padding: 40px 10px;
    }
}

@media screen and (max-width: 520px) {
    #about-advertisements-container .explanation-container {
        width: 95%;
    }
}

@media screen and (max-width: 400px) {
    #about-advertisements-container .explanation-container {
        width: 100%;
    }
}